/* eslint-disable react/jsx-props-no-spreading */
import { ListItemIcon, ListItemText } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ERoutePath } from '../../../../interface-adaptaters/interfaces/router/route.interface';
import { getUser, logoutStorage } from '../../../../libs/auth.service';
import { ETeam, UserProps } from '../../../../modules/user/domain/user.entity';
import { useUserFindMe } from '../../../../modules/user/queries/useUser';
import { MENU_LIST } from './menuList';

export function UserMenu() {
  const [anchorElUser, setAnchorElUser] = React.useState<boolean>(false);

  const user: UserProps = getUser();
  const userProfilePicture = useUserFindMe();

  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

      <Tooltip title="Open settings">
        <IconButton onClick={() => setAnchorElUser(true)} sx={{ p: 0 }}>
          <Avatar
            alt={userProfilePicture?.data?.contact.fullname}
            src={userProfilePicture?.data?.profilPicture?.url}
            sx={{ width: 48, height: 48 }}
          />
        </IconButton>
      </Tooltip>
      {anchorElUser ? (
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={anchorElUser}
          onClose={() => setAnchorElUser(false)}
        >
          {
            MENU_LIST.map((item) => {
              if ((!item.permissions || (item.permissions && user?.team?.some((t) => t.includes(item.permissions as unknown as ETeam))))
                && (!item.walterre || (item.walterre
                  && user.workspace.id.toString() === process.env.REACT_APP_WORKSPACE_ID_WALTERRE))
              ) {
                return (
                  <MenuItem
                    component={Link}
                    to={item.router}
                    sx={{
                      display: 'flex',
                      padding: '5px 10px',
                      textDecoration: 'none',
                      color: 'black',
                      '&:visited': {
                        textDecoration: 'none',
                      },
                      '&:active': {
                        textDecoration: 'none',
                      },
                    }}
                    onClick={() => {
                      setAnchorElUser(false);
                      if (item.router === ERoutePath.LOGIN) { logoutStorage(); }
                    }}
                  >
                    <ListItemIcon>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography textAlign="left">{item.label}</Typography>
                    </ListItemText>
                  </MenuItem>

                );
              }
              return null;
            })
          }
        </Menu>
      ) : null}
    </Box>
  );
}
