/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  combineReducers, configureStore,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { paginationSlice } from '../modules/pagination/redux/slice';
import { searchSlice } from '../modules/search/redux/slice';
import opportunityStudyReducer from '../ui/components/Project/CentralElement/OpportunityStudy/_redux/opportunityStudyReducer';

const reducers = combineReducers({
  [searchSlice.name]: searchSlice.reducer,
  [paginationSlice.name]: paginationSlice.reducer,
  opportunityStudy: opportunityStudyReducer,

});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }) as any,
  // }).concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;
export type AppThunk = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<any>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
