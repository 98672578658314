/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControl, InputAdornment, InputBaseComponentProps, Switch, TextField, Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { ElementType, forwardRef } from 'react';
import {
  Controller,
  RegisterOptions, useFormContext,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { Colors } from '../../../utils/colors';
import { FieldName } from '../../Project/style';
import { EStyleInput } from '../InputText';

export enum ENumberType {
  FLOAT = 'float',
  INTEGER = 'integer',
}
export interface InputNumberProps<T> {
  label: string;
  required?: boolean;
  name: keyof T;
  unity?: string;
  rules?: RegisterOptions;
  selectStyle?: EStyleInput;
  isRatio?: boolean;
  type?: ENumberType
  isThousandSeparator?: boolean;
  disabled?: boolean;
  consumptionStyle?: boolean;
  tooltip?: string;
  // eslint-disable-next-line react/require-default-props
  transform?: {
    input: (value: string) => string;
    output: (event: { target: { value: string } }) => number;
  };
}

type CustomProps = {
  // eslint-disable-next-line no-unused-vars
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const RatioSwitch = styled(Switch)(() => ({
  padding: 5,
  left: -15,
  width: 47,
  '& .Mui-checked': {
    transform: 'translateX(25px)',
  },
  '& .MuiSwitch-track': {
    borderRadius: 4,
    '&:before, &:after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      content: '"Ratio"',
      fontSize: 9,
      fontWeight: 600,
      left: 9,
      top: 16,
      color: Colors.black,
    },
    '&:after': {
      content: '""',
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    borderRadius: 3,
    width: 4,
    height: 20,
  },
}));

/** To factoring */
const IntegerFormatWithSpace = forwardRef<unknown, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue != null ? values.floatValue as unknown as string : null as unknown as string,
          },
        });
      }}
      thousandsGroupStyle="thousand"
      inputMode="numeric"
      decimalScale={0}
      thousandSeparator={' '}
    />
  );
});
const IntegerFormat = forwardRef<unknown, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue != null ? values.floatValue as unknown as string : null as unknown as string,
          },
        });
      }}
      thousandsGroupStyle="thousand"
      inputMode="numeric"
      decimalScale={0}
      thousandSeparator=""
    />
  );
});
const FloatFormatWithSpace = forwardRef<unknown, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue != null ? values.floatValue as unknown as string : null as unknown as string,
          },
        });
      }}
      inputMode="numeric"
      decimalScale={3}
      allowedDecimalSeparators={[',', '.']}
      thousandSeparator=" "
    />
  );
});
const FloatFormat = forwardRef<unknown, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue != null ? values.floatValue as unknown as string : null as unknown as string,
          },
        });
      }}
      inputMode="numeric"
      decimalScale={3}
      allowedDecimalSeparators={[',', '.']}
      thousandSeparator=""
    />
  );
});

export function InputNumber<T>({
  label, required, name, rules, unity, selectStyle, isRatio, type, isThousandSeparator, disabled, consumptionStyle, tooltip, transform,
}: InputNumberProps<T>) {
  const { formState: { errors }, control, trigger } = useFormContext();
  const handleBlur = () => {
    trigger(name as string);
  };
  return (
    <FormControl
      disabled={disabled}
      fullWidth
      style={{
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >

        {selectStyle === 'start' ? (
          <Tooltip title={tooltip}>
            <FieldName
              style={
                consumptionStyle
                  ? {
                    minWidth: '100px',
                  }
                  : {
                    width: '300px',
                    minWidth: '100px',
                  }
              }
            >
              {label}

            </FieldName>
          </Tooltip>

        ) : null}

        <Controller
          name={name as string}
          control={control}
          rules={{
            ...rules,
            required: {
              value: required as boolean,
              message: 'Ce champ est obligatoire',
            },
          }}
          render={({ field }) => (
            <Tooltip title={tooltip}>
              <TextField
                fullWidth
                disabled={disabled}
                required={required}
                value={transform?.input ? transform.input(field.value) : field.value}
                onChange={(e) => {
                  field.onChange(transform?.output ? transform.output(e) : e);
                  trigger(name as string);
                }}
                onBlur={() => {
                  field.onBlur();
                  handleBlur();
                }}
                defaultValue={field.value}
                InputProps={{
                  inputComponent: (
                    type === ENumberType.INTEGER
                      ? isThousandSeparator ? (
                        IntegerFormatWithSpace
                      ) : (IntegerFormat)
                      : isThousandSeparator
                        ? FloatFormatWithSpace
                        : FloatFormat) as unknown as ElementType<InputBaseComponentProps>,
                  startAdornment: isRatio ? (
                    <InputAdornment
                      position="start"
                      sx={{
                        marginRight: 0,
                        width: 35,
                      }}
                    >
                      <Controller
                        name={`${name as string}IsRatio`}
                        control={control}
                        render={(infoField) => (

                          <RatioSwitch
                            defaultChecked={infoField.field.value}
                            disabled={disabled}
                            value={infoField.field.value}
                            onClick={infoField.field.onChange}
                          />
                        )}
                      />
                    </InputAdornment>
                  ) : '',
                  endAdornment: unity ? (
                    <InputAdornment
                      sx={{
                        fontSize: 11,
                        fontWeight: 600,
                      }}
                      position="end"
                    >
                      <p>
                        {unity}
                      </p>
                    </InputAdornment>
                  ) : undefined,
                }}
                type="text"
                size="small"
                label={selectStyle === 'top' ? label : ''}
                error={Boolean(_.get(errors, `${name as string}.ref`, false))}
                helperText={_.get(errors, `${name as string}.message`, '') as string}
              />
            </Tooltip>
          )}
        />
      </div>
    </FormControl>
  );
}

InputNumber.defaultProps = {
  required: false,
  rules: {},
  unity: '',
  selectStyle: 'top',
  isRatio: false,
  type: ENumberType.INTEGER,
  isThousandSeparator: true,
  disabled: false,
  consumptionStyle: false,
  tooltip: '',
};
