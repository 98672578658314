/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface State {
  data: any | null;
  error: string | null;
}

const initialState: State = {
  data: null,
  error: null,
};

const slice = createSlice({
  name: 'oportunityStudy',
  initialState,
  reducers: {
    postDataSuccess(state, action) {
      state.data = action.payload;
      state.error = null;
    },
    postDataFailure(state, action) {
      state.error = action.payload;
      state.data = null;
    },
  },
});

export const { postDataSuccess, postDataFailure } = slice.actions;
export default slice.reducer;
